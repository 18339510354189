// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-marques-voitures-js": () => import("./../../../src/pages/marques-voitures.js" /* webpackChunkName: "component---src-pages-marques-voitures-js" */),
  "component---src-pages-voitures-en-promo-maroc-js": () => import("./../../../src/pages/voitures-en-promo-maroc.js" /* webpackChunkName: "component---src-pages-voitures-en-promo-maroc-js" */),
  "component---src-templates-actualite-js": () => import("./../../../src/templates/actualite.js" /* webpackChunkName: "component---src-templates-actualite-js" */),
  "component---src-templates-actualites-list-js": () => import("./../../../src/templates/actualites-list.js" /* webpackChunkName: "component---src-templates-actualites-list-js" */),
  "component---src-templates-actualites-tags-js": () => import("./../../../src/templates/actualites-tags.js" /* webpackChunkName: "component---src-templates-actualites-tags-js" */),
  "component---src-templates-marques-js": () => import("./../../../src/templates/marques.js" /* webpackChunkName: "component---src-templates-marques-js" */),
  "component---src-templates-models-js": () => import("./../../../src/templates/models.js" /* webpackChunkName: "component---src-templates-models-js" */)
}

