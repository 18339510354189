module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-149633319-1","head":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://mauto.ma"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Mauto.ma","short_name":"mautoma","icons":[{"src":"publiciconsicon-192x192.png","type":"image/png","sizes":"192x192"},{"src":"publiciconsicon-512x512.png","type":"image/png","sizes":"512x512"}],"start_url":"/","display":"standalone","background_color":"#2a354f","theme_color":"#2a354f","icon":"src/images/mauto_logo.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"8548a511033ed664ad9a55e4348ea602"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
